import React from 'react'

export default class ToDoList extends React.Component {

    state = {
        toDos: [
            {name: 'Do this'},
            {name: 'Do that'},
            {name: 'Do this'},
            {name: 'Do that'},
        ],
        val: '',
    };

    handleChange = (event) => {
        this.setState({val: event.target.value});
    };

    handleClick = () => {
        if (this.state.val !== '') {
            let toDos = this.state.toDos;
            toDos.push({name: this.state.val});
            this.setState({val: '', toDos: toDos});
        }
    };

    render() {
        return (
            <div>
                <ul>
                    {
                        this.state.toDos.map((el) => {
                            return(<li>{el.name}</li>);
                        })
                    }
                </ul>
                <input type="text" value={this.state.val} onChange={this.handleChange} />
                <button onClick={this.handleClick}>Ajouter !</button>
            </div>
        );
    }
}